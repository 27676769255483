import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { AppDrawer } from './AppDrawer';
import { MainAppBar } from './MainAppBar';
import { drawerWidth, drawerBreakpoint } from '../app.config';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar, // necessary for content to be below app bar
  content: {
    [theme.breakpoints.up(drawerBreakpoint)]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    flex: '1 1 auto',
    maxWidth: '100%',
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <MainAppBar />
      <AppDrawer />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </Box>
  );
};
