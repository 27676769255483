import React from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  logo: {
    height: 'auto',
    width: ({ size }) => size,
    padding: ({ padding }) => padding,
  },
}));

export const MainLogo = ({ size = 128, className }) => {
  const theme = useTheme();
  const logoUrl = theme.logo.content;
  const logoSize = theme.mainLogoWidth ?? size;
  const logoPadding = theme.mainLogoPadding ?? '';
  const classes = useStyles({ size: logoSize, padding: logoPadding });
  return (
    <img src={`/${logoUrl}`} className={clsx([classes.logo, className])} alt="SignPort logotype" />
  );
};
