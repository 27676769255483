import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns/fp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEmail } from '../../../utils/jwt';
import { emailRegex } from '../../../utils/regex';
import { DomainWarningMessage } from '../../../utils/userDomain';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: { padding: theme.spacing(1) },
  },
  subtitle: {
    padding: theme.spacing(2, 0, 1, 0),
    textWrap: 'nowrap',
  },
  delegateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  emailConnection: {
    flex: '2',
  },
  expirationDate: {
    flex: '1',
  },
  emailTextField: {
    width: '80%',
    minWidth: '300px',
  },
}));

export const AddConnection = ({ onSubmit, connections }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const classes = useStyles(theme);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const [expirationDate, setExpirationDate] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const validate = () => {
    if (connections.find((con) => con.ConnectedEmail === value)) {
      setError('Email already connected');
      return false;
    }
    const isEmail = String(value).toLowerCase().match(emailRegex);
    const userEmail = getEmail();
    if (isEmail && !userEmail.includes(value)) return true;
    setError(value.length ? 'Invalid email' : 'This field is required');
    return false;
  };
  const submit = (e) => {
    e.preventDefault();
    if (validate()) {
      openDialog();
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    if (error) setError('');
  };

  const onConfirm = () => {
    onSubmit(value, expirationDate);
    setValue('');
    setExpirationDate('');
    closeDialog();
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('Delegate my authority as administrator to')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{value}</DialogContentText>
          <DomainWarningMessage email={value} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} color="secondary" autoFocus variant="contained">
            {t('Delegate')}
          </Button>
        </DialogActions>
      </Dialog>
      <form className={classes.form} noValidate onSubmit={submit}>
        <Typography variant="subtitle1" className={classes.uppercase}>
          {t('Delegate my authority as administrator to')}
        </Typography>
        <Divider />
        <div className={classes.delegateContainer}>
          <div className={classes.emailConnection}>
            <InputLabel
              htmlFor="AddEmailConnection"
              id="AddEmailConnection"
              className={classes.subtitle}
              required
            >
              {t('Email')}
            </InputLabel>
            <TextField
              error={!!error}
              onChange={onChange}
              id="AddEmailConnection"
              label=""
              className={classes.emailTextField}
              value={value}
              inputProps={{
                maxLength: 254, // RFC 5321 standard
              }}
            />
            {error && <Typography color="error">{t(error)}</Typography>}
          </div>
          <br />
          <div className={classes.expirationDate}>
            <InputLabel
              htmlFor="AddExpirationDate"
              id="AddExpirationDate"
              className={classes.subtitle}
            >
              {t('Delegated authority valid until date')}
            </InputLabel>
            <TextField
              onChange={(e) => setExpirationDate(e.target.value)}
              id="AddExpirationDate"
              label=""
              type="date"
              value={expirationDate}
              className={classes.emailTextField}
              inputProps={{
                min: format('yyyy-MM-dd', new Date()),
                max: format('yyyy-MM-dd', new Date(9999, 11)),
              }}
            />
          </div>
        </div>
        <br />
        <div>
          <Button type="submit" variant="contained" color="primary">
            {t('Add')}
          </Button>
        </div>
      </form>
    </>
  );
};
