export const sv = {
  translations: {
    Welcome: 'Välkommen',
    redirectMessage1: 'Tjänsten SignPort har flyttat till: ',
    redirectMessage2: 'Skapa ej nya ärenden i denna tjänst då den kommer att avvecklas ',
    Document: 'Dokument',
    Documents: 'Dokument',
    Cases: 'Underskriftsuppdrag',
    'Sign request': 'Underskriftsuppdrag',
    'Sign requests': 'Underskriftsuppdrag',
    'Create request': 'Skapa uppdrag',
    'Create sign request': 'Skapa underskriftsuppdrag',
    'Light/Dark theme': 'Ljust/mörkt tema',
    'Dark theme': 'Mörkt tema',
    'Log out': 'Logga ut',
    Language: 'Språk',
    Created: 'Skapad',
    Administrator: 'Handläggare',
    Continue: 'Fortsätt',
    Prepare: 'Förbered',
    Signer: 'Undertecknare',
    Signers: 'Undertecknare',
    Name: 'Namn',
    Message: 'Meddelande',
    'Name (firstname lastname)': 'Namn (Förnamn Efternamn)',
    'Name (firstname lastname) (optional)': 'Namn (Förnamn Efternamn) (valfritt)',
    'Name is required': 'Namn krävs',
    'Organization is required': 'Organisation krävs',
    'Position is required': 'Befattning krävs',
    Status: 'Status',
    'Signers (email)': 'Undertecknare (e-post)',
    'Not accepted':
      'En eller flera filer accepteras inte. Filer måste vara av typen PDF, filnamnet bör ej innehålla specialtecken och får inte överskrida 100MB: {{str}}',
    Options: 'Alternativ',
    Revoke: 'Återkalla',
    Reject: 'Avvisa',
    'Revoke case': 'Återkalla uppdrag',
    'Reject case': 'Avvisa uppdrag',
    'Are you sure you want to revoke this case?':
      'Är du säker på att du vill återkalla detta uppdrag?',
    'Are you sure you want to delete this case?':
      'Är du säker på att du vill radera detta uppdrag?',
    'Are you sure you want to reject this case?':
      'Är du säker på att du vill avvisa detta uppdrag?',
    'You may state a reason in the text box below, which is sent to all parties in the sign request':
      'Du kan ange en orsak i textrutan nedan, som går till alla parter i underskriftsuppdraget',
    'You must state a reason in the text box below, which is sent to all parties in the sign request':
      'Du måste ange en orsak i textrutan nedan, som går till alla parter i underskriftsuppdraget',
    Revoked: 'Återkallad',
    Rejected: 'Avvisad',
    Sent: 'Skickad',
    Read: 'Läst',
    Expired: 'Utgången',
    Expiring: 'Utgår',
    Signed: 'Undertecknad',
    'Partially signed': 'Delvis undertecknad',
    Search: 'Sök',
    Delete: 'Radera',
    Edit: 'Ändra',
    Confirm: 'Bekräfta',
    Characters: 'tecken',
    'Change administrator': 'Byt handläggare',
    'Change administrator to': 'Byt handläggare till',
    'The maximum length of a message is 2048 characters':
      'Maximal längd på meddelanden är 2048 tecken',
    'Change Authorization as administrator to': 'Byt min behörighet som handläggare till',
    'Delete case': 'Radera uppdrag',
    Cancel: 'Avbryt',
    Close: 'Stäng',
    labelDisplayedRows_MaterialTable: '{from}-{to} av {count}',
    nRowsSelected_MaterialTable: '{0} rad(er) valda',
    rows: 'rader',
    Login: 'Logga in',
    CaseManager: 'Handläggare',
    'Mobilt BankID': 'Mobilt BankID',
    'Net iD Access': 'Net-iD',
    SMS: 'SMS',
    'Mobile number': 'Mobilnummer (Ex: +46785555555)',
    'Mobile number is required': 'Mobilnummer krävs',
    Actions: 'Funktioner',
    'No records to display': 'Ingen data att visa',
    Filter: 'Filtrera',
    Show: 'Visa',
    Download: 'Ladda ner',
    DownloadAll: 'Ladda ner alla',
    Sign: 'Skriv under',
    'Sign in order': 'Måste skrivas under i ordning',
    'Start signing': 'Starta signering',
    'Enter your signing details': 'Fyll i dina signeringsuppgifter',
    Organization: 'Organisation',
    'Organization (optional)': 'Organisation (valfritt)',
    Position: 'Befattning',
    'Position (optional)': 'Befattning (valfritt)',
    Messages: 'Meddelanden',
    all: 'alla',
    'not yet signed': 'de som ej undertecknat',
    administrator: 'handläggare',
    none: 'ingen',
    'chat message to all parties in the signing assignment':
      'chattmeddelande till samtliga parter i underskriftuppdraget',
    'Also send email to': 'Skicka även e-post till',
    'Write message': 'Skriv meddelande',
    'No message to show': 'Inga meddelande att visa',
    'Message history': 'Meddelandehistorik',
    'Awaiting your signature': 'Din underskrift förväntas',
    'You are currently not allowed to sign.': 'Du kan inte skriva under just nu.',
    'Others must sign before you': 'Andra måste skriva under före dig',
    Send: 'Skicka',
    // 'Write a message that you want to send': 'Skriv ett meddelande som du vill skicka',
    'Send message to all parties in the sign request':
      'Skicka meddelande till alla parter i underskriftsuppdraget',
    'Send reminder to all parties in the sign request who have not signed the sign request':
      'Skicka påminnelse till alla parter i underskriftsuppdraget som inte skrivit under',
    // 'Send reminder': 'Skicka påminnelse',
    // 'Revoke sign request': 'Återkalla underskriftsbegäran',
    // 'Delete sign request': 'Ta bort underskriftsbegäran',
    // 'Sign all selected': 'Skriv under alla valda dokument på en gång',
    'Start email verification process': 'Påbörja e-post-verifiering',
    'Upload documents': 'Ladda upp dokument',
    'Drop document here or': 'Dra filen hit eller',
    'Drop document here or_plural': 'Dra filer hit eller',
    'select a file': 'välj fil',
    'select a file_plural': 'välj filer',
    'Signer details': 'Undertecknare {{index}}',
    Email: 'E-post',
    Username: 'Användarnamn',
    orgAffiliation: 'Personlig organisationsidentifierare',
    orgAffiliationServiceId: 'Tjänste-ID',
    efosidEmployeeNumber: 'Anställningsnummer',
    'HSA-ID': 'HSA-ID',
    orgAffiliation_helperText:
      'Format: identifierare@organisationsnummer, där identifieraren kan vara ett användarnamn eller ett anställningsnummer, men andra format kan förekomma. Exempel: abc123@0123456789',
    orgAffiliationServiceId_helperText:
      'Ditt tjänste-ID. Format: id@orgnr, t.ex. "12345@0123456789", där "12345" är ett tjänste-ID, och "0123456789" är ett organisationsnummer.',
    efosidEmployeeNumber_helperText: 'Ditt anställningsnummer.',
    hsaid_helperText:
      'Format: [landskod][organisationsnummer]-[löpnummer]. Exempel: SE2321000016-1003',
    upnUsername_helperText:
      'Ett användarnamn i form av en (intern) emailadress. T.ex josmi1@example.com, där "josmi1" är ett användarnamn.',
    'Authentication method': 'Autentiseringsmetod',
    'Require personal number': 'Kräv personnummer',
    'At least one method required': 'Minst en metod måste anges',
    'Choose authentication method': 'Välj autentiseringsmetod',
    'Message to signers': 'Meddelande till undertecknare',
    'Message (optional)': 'Meddelande till alla undertecknare (valfritt)',
    'Expiration date': 'Utgångsdatum',
    'Choose date (optional)': 'Välj datum (valfritt)',
    'Add signer': 'Lägg till undertecknare',
    'Personal number': 'Personnummer (12 tecken)',
    'Swedish personal ID number. 12 digits without spaces and hyphens.':
      'Svenskt personnummer. 12 siffror utan mellanslag eller bindestreck.',
    'Personal number is required': 'Personnummer krävs',
    'You are not able to sign this request': 'Du kan inte skriva under detta uppdrag',
    'You do not have permission to view this sign request':
      'Du har inte behörighet att visa detta uppdrag',
    'Some sign request require validation by personal number':
      'Är e-postadressen korrekt? Vissa underskrift uppdrag kräver validering med personnummer. Kontakta handläggaren för mer information.',
    'You might not have permission for this': 'Du kanske inte har rätt behörighet',
    'Invalid input': 'Felaktigt värde',
    'Email is required': 'E-post krävs',
    'Username is required': 'Användarnamn krävs',
    'Service-ID is required': 'Tjänste-ID krävs',
    'Organization Identity is required': 'Organisationsidentitet krävs',
    'HSA-ID is required': 'HSA-ID krävs',
    'Add at least one file': 'Bifoga minst en fil',
    QuickSign: 'QuickSign',
    'Sign with': 'Skriv under med {{method}}',
    'Back to sign requests': 'Tillbaka till alla uppdrag',
    Author: 'Skapad av',
    'Email addresses connected to your account': 'E-postadresser kopplade till ditt konto',
    'Continue to the service': 'Fortsätt till tjänsten',
    'Verify email address': 'Verifiering av e-postadress',
    'You can choose to register an additional email address to your account':
      'Du kan välja att koppla fler e-postadresser till ditt konto. Detta görs under "Inställningar" - "Hantera e-postadresser".',
    'To connect additional email addresses you have to log out and log in as a signer, and then start an email verification process':
      'För att koppla ytterligare e-postadresser behöver du logga ut och logga in som undertecknare, och sedan genomföra en e-post-verifiering',
    'Please make sure you have entered the correct url and have connected the correct email adress to your account':
      'Kontrollera att du angivit rätt adress och att du har kopplat rätt e-postadress till ditt konto',
    'You can connect additional adresses in the': 'Du kan koppla fler e-postadresser i ',
    'settings page': 'inställningar',
    'The sign request was not found': 'Underskriftsärendet hittades inte',
    'No authentication method is available for you to sign this sign request':
      'Hittade inga autentiseringsmetoder för dig att skriva under detta underskriftsärende',
    'Please make sure you type the correct address': 'Kontrollera att du skriver rätt adress',
    Validate: 'Validera',
    and: 'och',
    'Validate a digitally signed document': 'Validera ett elektroniskt underskrivet dokument',
    'Documents (PDF) that have been digitally signed by one or more persons with a DIGG approved digital signature service can be validated here':
      'Dokument (PDF) som är elektroniskt underskrivna av en eller flera personer med en av DIGG godkänd underskriftstjänst kan valideras här',
    'Validate directly in Adobe Acrobat Reader': 'Validera direkt i Adobe Acrobat Reader',
    'It is also possible to validate directly in acrobat reader. In order to do that you need to download and install the public certificate in Acrobat Reader':
      'Det är även möjligt att göra en validering direkt i Acrobat Reader. Då behöver Cybercoms publika certifikat laddas ner och installeras i din acrobat reader',
    'It is also possible to validate directly in Acrobat Reader. In order to validate "green", you must download and add SignPorts public certificate to the trusted certificates list. A "yellow" validation means that Acrobat Reader does not trust the certificate.':
      'Det är även möjligt att validera direkt i Acrobat Reader. För att validera "grönt" måste SignPorts publika certifikat laddas ner och läggas till i listan av pålitliga certifikat. En "gul" validering betyder att Acrobat Reader inte litar på certifikatet. Validering i Adobe Acrobat Reader utgår inte från DIGG\'s krav.',
    'How to install the SignPort-Certificate':
      'Instruktioner för att installera SignPorts certifikat',
    Donwload: 'Ladda ner',
    'Other validation options': 'Andra valideringsalternativ',
    'Double-click the certificate file to add it as “Trusted” in Acrobat Reader.':
      'Dubbelklicka på filen för att ladda in den som ”Pålitlig” i Acrobat Reader.',
    'Press the ”Set Contact Trust…” button.': 'Tryck ”Ange pålitlig kontakt…”',
    'Check the ”Use this certificate as a trusted root” box.':
      'Kryssa i ”Använd det här certifikatet som ett pålitligt rotcertifikat”.',
    'A computer restart might be required for the changes to be taken into effect.':
      'Eventuellt behövs en omstart för att ändringen ska aktiveras.',
    'Cybercoms public certificate': 'Cybercoms publika certifikat',
    'Instruction on how to install the certificate':
      'Instruktion för hur du installerar certifikatet',
    Approved: 'Godkänd',
    'Not approved': 'Underkänd',
    'Approved, with remarks': 'Godkänd, med anmärkning',
    Warning: 'Varning',
    'The document has not been changed since it has been signed. The persons listed in the digital signature are the persons that have signed the document.':
      'Dokumentet har inte ändrats sedan det skrevs under. Personerna som listas i den elektroniska underskriften är de som har skrivit under dokumentet.',
    "The document has not been changed, but the signatures can not be correctly validated. The document doesn't live up to DIGG's requirements for a digitally signed document.":
      'Dokumentet är inte ändrat, men underskrifterna kan inte valideras korrekt. Dokumentet uppfyller inte DIGG’s krav för ett elektroniskt underskrivet dokument.',
    'The document might have been changed since it has been signed and/or the signatures are not valid.':
      'Dokumentet kan ha ändrats sedan det skrevs under och/eller underskrifterna är ogiltiga.',
    'Something went wrong. Check if the file you upploaded is of the correct type (.pdf och .xml)':
      'Någonting gick fel. Kontrollera att filen är av rätt typ (.pdf eller .xml)',
    'Something went wrong': 'Någonting gick fel',
    'Show report': 'Visa rapport',
    'New validation': 'Ny validering',
    'Detailed report of the validation': 'Detaljerad rapport från valideringen',
    'Below follows a detailed report on all the performed checks and the result of this examination':
      'Nedan följer en detaljerad rapport över vilka punkter som har kontrollerats och resultatet av kontrollen',
    'Detailed report': 'Detaljerad rapport',
    'Signature occasion': 'Underskriftstillfälle {{occasions}}',
    'Validation status for the signature': 'Valideringsstatus för signaturen',
    'Validation status for signing certificate': 'Valideringsstatus för signerande certifikat',
    'Validation status for certificate chain, certificate':
      'Valideringsstatus för utfärdarkedja, certifikat',
    'The signature belongs to': 'Underskriften är utförd av {{signer}}',
    "The digital signature service's certificate chain is":
      'Underskriftstjänstens kedja av utfärdare är {{namesAuthorizationServices}}',
    'The moment of signing is': 'Tiden för underskrift är {{moment}}',
    missing: 'saknas',
    'Timing of the validation report:': 'Tidpunkt för valideringsrapport: {{timing}}',
    'Hide report': 'Göm rapport',
    Overview: 'Översikt',
    'Summary: The document has been signed on':
      'Sammanfattning: Dokumentet har skrivits under vid $t(occasion, {"count": {{number}}})',
    occasion: '{{count}} tillfälle',
    occasion_plural: '{{count}} tillfällen',
    'In total there have been': 'Totalt utfördes det $t(check, {"count": {{number}}})',
    check: '{{count}} valideringskontroll',
    check_plural: '{{count}} valideringskontroller',
    'The number of approvals sentence':
      'Antalet valideringskontroller som fick godkänt är {{approvals}}, varav {{count}} är godkänd med anmärkning',
    'The number of approvals sentence_plural':
      'Antalet valideringskontroller som fick godkänt är {{approvals}}, varav {{count}} är godkända med anmärkning',
    'All approvals (with or without a remark) have been highlighted green. Any checks that have not been approved have gotten a warning color, either orange (status Warning) or red (status Not Approved).':
      'Alla godkända kontroller (utan eller med anmärkning) har fått en grön markering. Eventuella icke godkända kontroller har fått en varningsfärg, antingen orange (status Varning) eller röd (status Underkänd).',
    'something went wrong': 'Något gick fel',
    'error message': 'Felmeddelande: ',
    'error code': 'Felkod: ',
    'an error occurred': 'Ett fel inträffade',
    'are you still there': 'Är du fortfarande där?',
    'You are not active': 'Du är inte aktiv och blir av säkerhetsskäl strax utloggad',
    'time remaining': 'Tid kvar: ',
    'Im here': 'Jag är här',
    'SMS is a weaker authentication method': 'SMS är en svagare autentiseringsmetod',
    'Document successfully signed': 'Underskriften lyckades!',
    signpage: 'Underskriftssida',
    language: 'Språk',
    'du saknar tyvärr behörighet att använda den här tjänsten som handläggare. kontakta din administratör för att begära behörigheten.':
      'Du saknar tyvärr behörighet att använda den här tjänsten som Handläggare. Kontakta din administratör för att begära behörigheten.',
    'Move Up': 'Flytta Upp',
    'Move down': 'Flytta Ner',
    'No signing page': 'Ingen underskriftssida',
    'Choose AuthenticationMethod': 'Välj autentiseringsmetod',
    'Page Settings': 'Sidinställningar',
    'Delegate authority as administrator': 'Delegera behörighet som handläggare',
    'Connected Email': 'Kopplad E-post',
    'Delete Connection': 'Radera koppling',
    'Remove connection': 'Ta bort koppling',
    'The person with this email-address will no longer have your permission as administrator':
      'Personen med följande e-post kommer inte längre ha handläggarrättigheter till dina ärenden',
    'My authority as administrator delegated to': 'Min behörighet som handläggare delegerat till',
    'Authority as administrator delegated to me': 'Behörighet som handläggare delegerat till mig',
    'Invalid email': 'Otillåten e-post',
    'invalid personal number': 'Ogiltigt personnummer',
    'Email already connected': 'Behörighet redan delegerad',
    'This field is required': 'Obligatoriskt',
    'Create delegate authority connection': 'Skapa koppling',
    'The following address will be allowed to administer your documents':
      'Följande E-poster kommer att få handläggarrättigheter för dina ärenden',
    'Make Connection': 'Skapa koppling',
    'Delegate my authority as administrator': 'Delegera min behörighet som handläggare',
    Submit: 'Skicka',
    Settings: 'Inställningar',
    'Select which cases to view': 'Välj vilka ärenden att visa',
    'My cases': 'Mina ärenden',
    'Delegated cases': 'Delegerade ärenden',
    'All cases': 'Alla ärenden',
    'How to delegate your authority as administrator':
      'Hur man delegerar sin behörighet som handläggare',
    'This feature allows you to share your created documents with other users':
      'Denna funktionen låter dig dela dina uppdrag med andra handläggare',
    'Allow other users to administer your documents':
      'Tillåt andra handläggare att handlägga dina ärenden',
    'Delegate my authority as administrator to': 'Delegera min behörighet som handläggare till',
    'Delegated authority valid until date': 'Delegering gäller till och med datum',
    Delegate: 'Delegera',
    'Remove delegation': 'Ta bort delegering',
    Add: 'Lägg till',
    'State the reason for your rejection': 'Ange orsak till att du avvisar',
    'state the reason for your revocation': 'Ange orsak till att du återkallar',
    delegateHelpTitle: 'Här kan du delegera din behörighet som handläggare.',
    delegateHelp:
      ' Den här funktionen ger dig möjlighet att låta andra handläggare agera som handläggare på de underskiftsuppdrag som du har skapat. Du lägger till dem genom att ange deras e-postadress. De du har lagt till listas under "Min behörighet som handläggare delegerad till". På samma sätt kan andra handläggare delegera sin behörighet till dig. Dessa listas under "Behörighet som handläggare delegerad till mig". Du kan ta bort delegering med radera-knappen i respektive lista.',
    clearFilters: 'Rensa alla filter',
    from_date: 'Från datum',
    to_date: 'Till datum',
    createdByMe: 'Ärenden skapade av mig',
    notCreatedByMe: 'Ärenden skapade av andra',
    signerIsMe: 'Ärenden där jag är undertecknare',
    signerIsNotMe: 'Ärenden där jag inte är undertecknare',
    Ok: 'Ok',
    'Please make sure you type the correct personal number':
      'Kontrollera att du skriver rätt personnummer',
    'First Page': 'Första sidan',
    'Previous Page': 'Föregående sida',
    'Next Page': 'Nästa sida',
    'Last Page': 'Sista sidan',
    'Zoom out': 'Zooma ut',
    'Zoom in': 'Zooma in',
    'Side menu': 'Sidomeny',
    Languages: 'Språk',
    'available languages': 'tillgängliga språk',
    Profile: 'Profil',
    'Submit a valid email address': 'Ange en giltig e-postadress',
    'Submit a valid username': 'Ange ett giltigt användarnamn',
    'Submit a valid Organization Identity': 'Ange en giltig organisationsidentitet',
    'Submit a valid employee number': 'Ange ett giltigt anställningsnummer',
    'Submit a valid personal number': 'Ange ett giltigt personnummer',
    'Submit a valid eIDAS id': 'Ange ett giltigt ID',
    'Submit a valid Service-ID': 'Ange ett giltigt tjänste-ID',
    'Submit a valid HSA-ID': 'Ange ett giltigt HSA-ID',
    'Authentication method required': 'Autentiseringsmetod krävs',
    'Remove Email': 'Ta bort e-postadress',
    'Add Email': 'Lägg till e-postadress',
    'Manage my emails': 'Hantera e-postadresser',
    manageEmailsHelpTitle: 'Hantera e-postadresser',
    manageEmailsHelp:
      'Här kan du lägga till och ta bort e-postadresser som är kopplade till ditt konto.',
    'The following email address will no longer be associated with this account':
      'Följande e-postadress kommer inte längre att kopplas till det här kontot',
    'Connected Emails': 'Kopplade e-postadresser',
    'Don`t show this message again': 'Visa inte det här meddelandet igen',
    'Connect a new email address to my account': 'Koppla en ny e-postadress till mitt konto',
    'If you remove your last email address, you will be logged out of the app':
      'Om du tar bort din sista e-postaddress kommer du att logas ut ur tjänsten',
    'The provided email address does not have the same domain as your email address':
      'Den angivna e-postadressen har inte samma domän som din e-postadress',
    'A user with an email address outside your organization might not be able to use the service as an administrator':
      'Det är inte säkert att en användare med en e-postadress utanför din organisation kan använda tjänsten som handläggare',
    'signpage required': 'En signatursida måste anges',
    'same email twice': 'Samma e-postadress får ej vara med fler än en gång',
    'Back to login page': 'Tillbaka till inloggningssidan',
    'Validate a document': 'Validera ett dokument',
    'The file ': 'Filen ',
    ' is not a valid PDF file. Please make sure the file is not damaged or corrupted and try again.':
      ' är inte en giltig PDF-fil. Kontrolera att filen inte är skadad eller korrupt och försök igen."',
  },
};
