import React, { useEffect, useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { createLocalStorageStateHook } from 'use-local-storage-state';
import { Checkbox, FormControlLabel, Tabs, Tab } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { partition } from 'ramda';
import { AppMachineContext } from '../store/appMachine';
import { MainLogo } from './MainLogo';
import { ProfileMenu } from './ProfileMenu';
import { LangMenu } from './LangMenu';
import { UserConnectedEmails } from './UserConnectedEmails';
import { ErrorPage } from './exceptionHandler/ErrorPage';
import { LoginError } from './exceptionHandler/LoginError';
import { userHasEmail } from '../utils/jwt';
import { write, sessionRemove } from '../utils/storage';
import { theme as customTheme } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.login-bg': {
      background: theme.loginBackground ? theme.loginBackground : 'url(/bg1.jpeg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    minHeight: 600,
    justifyContent: 'center',
  },
  paperContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    '& > button:not(:last-child), & > p': {
      marginBottom: theme.spacing(2),
    },
  },
  menu: {
    position: 'absolute',
    right: '3%',
    top: 10,
  },
  langMenu: {
    position: 'absolute',
    left: '2%',
    top: 10,
  },
  logo: {
    padding: theme.spacing(0, 0, 2, 0),
    ...theme.loginLogoStyle,
  },
  message: {
    padding: theme.spacing(1, 2),
    background: theme.palette.error.main,
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
  },
  loginHeader: {
    ...theme.loginHeaderStyle,
  },
  loginButton: {
    ...theme.loginButtonStyle,
  },
  redirectMessage: {
    background: '#fff00f',
    padding: '16px',
    borderRadius: '4px',
    margin: '8px 0px',
    textAlign: 'center',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',
  },
  tabLabel: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: '400',
  },
  tabsMB: {
    marginBottom: '16px',
  },
}));

const useSkipVerification = createLocalStorageStateHook('SkipVerification', false);

export const LoginPage = () => {
  const [loginContentHeight, setLoginContentHeight] = useState(800);

  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const mainLogoWidth = '80%';

  const [
    {
      matches,
      context: {
        authIdps,
        verificationOptional,
        verificationDisabled,
        loginMessage,
        verificationUrl,
      },
    },
    send,
  ] = useContext(AppMachineContext);

  const logOut = () => {
    history.push('/');
    write('logOut', 'true');
    sessionRemove('toURL');
    send('LOG_OUT');
  };

  const login = matches('login');
  const verifyEmail = matches('verifyEmail');
  const redirecting = matches('redirecting');

  const [skipVerification, setSkipVerification] = useSkipVerification();

  const theme = useTheme();
  const { features } = theme;
  const publicValidation =
    features && 'publicValidation' in features ? features.publicValidation : false;

  const [currentTab, setCurrentTab] = useState(1);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    localStorage.setItem('loginTab', newValue);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('admin')) {
      localStorage.setItem('loginTab', 0);
    } else if (queryParams.has('signer')) {
      localStorage.setItem('loginTab', 1);
    }
    history.replace({
      search: '',
    });
    const savedTab = localStorage.getItem('loginTab') ?? '1';
    setCurrentTab(parseInt(savedTab, 10));
  }, [location.search, history]);
  useEffect(() => {
    if (redirecting) {
      setTimeout(() => {
        send('CHECKING');
      }, 2000);
    }
  }, [redirecting]);
  useEffect(() => {
    document.body.className = 'login-bg';
    return () => {
      document.body.className = '';
    };
  }, []);

  useEffect(() => {
    if (verificationDisabled || (skipVerification && userHasEmail())) send('CONTINUE');
  }, [verificationDisabled]);

  const logIn = (url) => () => {
    send('LOG_IN', { url });
  };

  const [caseManagerIdps, signerIdps] = partition(
    (idp) => idp.isOrganizationIdentityProvider,
    authIdps,
  );

  useEffect(() => {
    setLoginContentHeight(Math.max(caseManagerIdps.length, signerIdps.length) * 52 + 270);
  }, [caseManagerIdps, signerIdps]);

  const languagePrefix = i18n.language.split('-')[0];
  const separateLoginOptions =
    theme.features && 'separateLoginOptions' in features ? features.separateLoginOptions : null;

  const content = login ? (
    <>
      {customTheme.redirectUrl && (
        <span className={classes.redirectMessage}>
          {t('redirectMessage1')}
          <a href={customTheme.redirectUrl}>{customTheme.redirectUrl}</a> <br />
          {t('redirectMessage2')}
          {customTheme.redirectDate}.
        </span>
      )}

      {separateLoginOptions ? (
        <>
          <Typography paragraph variant="h5" className={classes.loginHeader}>
            {t('Login')}
          </Typography>

          <Tabs
            onChange={handleChange}
            value={currentTab}
            indicatorColor="primary"
            className={classes.tabsMB}
          >
            <Tab
              label={t('Signer')}
              className={classes.tabLabel}
              value={1}
              aria-label={t('Signer')}
            />
            <Tab
              label={t('CaseManager')}
              className={classes.tabLabel}
              value={0}
              aria-label={t('CaseManager')}
            />
          </Tabs>

          {currentTab === 0 &&
            caseManagerIdps.map((idp) => (
              <Button
                key={idp.displayName}
                variant="contained"
                fullWidth
                color="primary"
                onClick={logIn(idp.entityId)}
                disabled={!login}
                className={classes.loginButton}
              >
                {idp[`displayName_${languagePrefix}`]
                  ? idp[`displayName_${languagePrefix}`]
                  : idp.displayName}
              </Button>
            ))}

          {currentTab === 1 &&
            signerIdps.map((idp) => (
              <Button
                key={idp.displayName}
                variant="contained"
                fullWidth
                color="primary"
                onClick={logIn(idp.entityId)}
                disabled={!login}
                className={classes.loginButton}
              >
                {idp[`displayName_${languagePrefix}`]
                  ? idp[`displayName_${languagePrefix}`]
                  : idp.displayName}
              </Button>
            ))}
        </>
      ) : (
        <>
          <Typography paragraph variant="h5" className={classes.loginHeader}>
            {t('Login')}
          </Typography>
          {authIdps.map((idp) => (
            <Button
              key={idp.displayName}
              variant="contained"
              fullWidth
              color="primary"
              onClick={logIn(idp.entityId)}
              disabled={!login}
              className={classes.loginButton}
            >
              {idp[`displayName_${languagePrefix}`]
                ? idp[`displayName_${languagePrefix}`]
                : idp.displayName}
            </Button>
          ))}
        </>
      )}
      {publicValidation ? (
        <Link href="/validate" className={classes.homePageLink} variant="subtitle1">
          {t('Validate a document')}
        </Link>
      ) : null}
    </>
  ) : verifyEmail ? (
    <>
      {verificationOptional && (
        <>
          <Typography paragraph variant="h5">
            {t('Welcome')}
          </Typography>
          <UserConnectedEmails listItemProps={{ disableGutters: true }} />
          <Button color="primary" fullWidth variant="contained" onClick={() => send('CONTINUE')}>
            {t('Continue to the service')}
          </Button>
        </>
      )}
      <Typography paragraph variant="h5">
        {t('Verify email address')}
      </Typography>
      {!verificationOptional && (
        <>
          <Button color="primary" fullWidth variant="contained" onClick={logIn(verificationUrl)}>
            {t('Start email verification process')}
          </Button>
          <Button color="primary" fullWidth variant="text" onClick={logOut}>
            {t('Cancel')}
          </Button>
        </>
      )}
      {verificationOptional && (
        <>
          <Typography paragraph>
            {t('You can choose to register an additional email address to your account')}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={skipVerification}
                onChange={(e) => setSkipVerification(e.target.checked)}
                name="SkipVerification"
              />
            }
            label={t('Don`t show this message again')}
          />
        </>
      )}
    </>
  ) : (
    <CircularProgress />
  );

  return (
    <Container maxWidth="xs" className={classes.container}>
      <div className={classes.paperContainer} style={{ height: `${loginContentHeight}px` }}>
        <Paper className={classes.root}>
          <Switch>
            <Route path="/exception/:errorMsg?/:errorCode?">
              <ErrorPage />
            </Route>
            <Route path="/:showError?/:errorMsg?">
              <LangMenu
                className={classes.langMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              />
              <MainLogo size={mainLogoWidth} className={classes.logo} />
              <ProfileMenu moreIcon noLogout className={classes.menu} />
              {loginMessage && <Paper className={classes.message}>{loginMessage}</Paper>}
              <LoginError />
              {content}
            </Route>
          </Switch>
        </Paper>
      </div>
    </Container>
  );
};
